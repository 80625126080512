// This should be removed when the Balance Footer component is released.

@use '../01_global/config' as *;

@mixin container(
  $isFullWidth: false,
  $isFullHeight: false,
  $verticalSpacing: null
) {
  display: $container-outer-display;
  width: $container-outer-width;

  @if $isFullWidth == true {
    padding-left: $container-outer-padding-none;
    padding-right: $container-outer-padding-none;

    > * {
      max-width: $container-inner-width-full;
    }
  }

  @if $isFullWidth == false {
    padding-left: $container-outer-padding-small;
    padding-right: $container-outer-padding-small;

    @media (min-width: $breakpoint-min-width-tablet-large) {
      padding-left: $container-outer-padding-large;
      padding-right: $container-outer-padding-large;
    }

    > * {
      margin: $container-inner-margin;
      max-width: $container-inner-width-max;
      width: $container-inner-width-full;
    }
  }

  @if $isFullHeight {
    min-height: $container-outer-height-full;

    > * {
      height: $container-inner-height-full;
    }
  }

  @if $verticalSpacing {
    > * {
      padding-bottom: $verticalSpacing;
      padding-top: $verticalSpacing;
    }
  }
}

@mixin stack($verticalSpacing: null, $isCentered: false) {
  display: $stack-outer-display;
  flex-direction: $stack-outer-flex-direction;
  justify-content: $stack-outer-justify-content;
  width: $stack-outer-width;

  > * {
    margin-bottom: $stack-vertical-spacing-0;
    margin-top: $stack-vertical-spacing-0;
  }

  @if $verticalSpacing {
    > * + * {
      margin-top: $verticalSpacing;
    }
  }

  @if $isCentered {
    align-items: $stack-outer-alignment-center;
  }
}

.footer {
  @include container();

  padding-bottom: $space-120;
  padding-top: $space-130;

  &__inner {
    @include stack(
      $isCentered: true,
      $verticalSpacing: $stack-vertical-spacing-90
    );
  }

  &__footnotes-list {
    padding-left: $footer-footnotes-list-padding-left;
  }

  &__footnotes-item p {
    max-width: $footer-footnotes-text-max-width;
  }

  &__disclosures {
    @include stack($verticalSpacing: $stack-vertical-spacing-40);

    align-items: center;
  }

  &__disclosure,
  &__footnotes-item {
    @include text-style-body-4;

    color: $footer-footnotes-text-color;
    max-width: 100%;
    width: 100%;
  }

  &__nav {
    &-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;

      @media (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &-item {
      text-align: center;

      &:not(:last-child) {
        @media (min-width: 768px) {
          margin-right: $footer-links-item-margin-right;
        }
      }

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }

    &-link {
      @include text-style-body-3(strong);

      color: $footer-links-link-color;
    }
  }

  &__legal--global {
    @include stack(
      $isCentered: true,
      $verticalSpacing: $stack-vertical-spacing-30
    );
  }

  &__copy {
    @include text-style-body-4;

    max-width: none;
    text-align: center;
  }

  &__icons {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > * + * {
      margin-left: $footer-icons-icon-margin-left;
    }

    svg {
      fill: $footer-icons-icon-color;
      height: $footer-icons-icon-height;
    }

    img {
      height: $footer-icons-icon-height;
    }
  }
}
