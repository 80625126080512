@use '../01_global/config' as *;

.header {
  display: flex;
  justify-content: center;
  padding-bottom: $space-100;
  padding-top: $space-40;

  .regions-logo {
    height: 41px;
    width: 182px;
  }
}
