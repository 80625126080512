@use '../01_global/config' as *;

.auth-heading {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  &__description {
    @include text-style-body-1(strong);

    @media (min-width: $mobile-breakpoint) {
      @include text-style-heading-4;
    }
  }

  &__error {
    color: $color-red-60;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 628px;

    a {
      color: inherit;
    }
  }
}
