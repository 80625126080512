@use '../01_global/config' as *;

.segmentation-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 628px;
  width: 100%;
}

.large-icon-button {
  outline: none;
  width: 100%;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    transition: box-shadow $transition-duration-slow $transition-timing-ease-out;
  }

  &:focus,
  &:hover {
    > div {
      box-shadow: $shadow-30;
    }
  }

  span {
    align-items: center;
    display: flex;
  }

  svg {
    height: 36px;
    margin-right: $space-40;
    width: 36px;

    @media (min-width: 916px) {
      height: 48px;
      margin-right: $space-60;
      width: 48px;
    }
  }

  .icon {
    height: 0.875rem;
    margin-right: 0;
  }
}
