// Router Div
/* stylelint-disable-next-line */
#root > div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  place-content: center;
}
