@use '~@ux/balance-tokens/build/tokens.scss' as *;

@mixin text-style-body-1($weight: default) {
  font-family: $text-style-body-1-font-family;
  font-size: $text-style-body-1-font-size;
  font-weight: $text-style-body-1-font-weight-default;
  letter-spacing: $text-style-body-1-letter-spacing;
  line-height: $text-style-body-1-line-height;

  @if $weight == 'strong' {
    font-weight: $text-style-body-1-font-weight-strong;
  }
}

@mixin text-style-body-2($weight: default) {
  font-family: $text-style-body-2-font-family;
  font-size: $text-style-body-2-font-size;
  font-weight: $text-style-body-2-font-weight-default;
  letter-spacing: $text-style-body-2-letter-spacing;
  line-height: $text-style-body-2-line-height;

  @if $weight == 'strong' {
    font-weight: $text-style-body-2-font-weight-strong;
  }
}

@mixin text-style-body-3($weight: default) {
  font-family: $text-style-body-3-font-family;
  font-size: $text-style-body-3-font-size;
  font-weight: $text-style-body-3-font-weight-default;
  letter-spacing: $text-style-body-3-letter-spacing;
  line-height: $text-style-body-3-line-height;

  @if $weight == 'strong' {
    font-weight: $text-style-body-3-font-weight-strong;
  }
}

@mixin text-style-body-4($weight: default) {
  font-family: $text-style-body-4-font-family;
  font-size: $text-style-body-4-font-size;
  font-weight: $text-style-body-4-font-weight-default;
  letter-spacing: $text-style-body-4-letter-spacing;
  line-height: $text-style-body-4-line-height;

  @if $weight == 'strong' {
    font-weight: $text-style-body-4-font-weight-strong;
  }
}

@mixin text-style-heading-1 {
  font-family: $text-style-heading-1-font-family;
  font-size: $text-style-heading-1-font-size-min;
  font-size: clamp(
    #{$text-style-heading-1-font-size-min},
    calc(1rem + #{$text-style-heading-1-font-size-modifier}),
    #{$text-style-heading-1-font-size-max}
  );
  font-weight: $text-style-heading-1-font-weight;
  letter-spacing: $text-style-heading-1-letter-spacing;
  line-height: $text-style-heading-1-line-height;
}

@mixin text-style-heading-2 {
  font-family: $text-style-heading-2-font-family;
  font-size: $text-style-heading-2-font-size-min;
  font-size: clamp(
    #{$text-style-heading-2-font-size-min},
    calc(1rem + #{$text-style-heading-2-font-size-modifier}),
    #{$text-style-heading-2-font-size-max}
  );
  font-weight: $text-style-heading-2-font-weight;
  letter-spacing: $text-style-heading-2-letter-spacing;
  line-height: $text-style-heading-2-line-height;
}

@mixin text-style-heading-3 {
  font-family: $text-style-heading-3-font-family;
  font-size: $text-style-heading-3-font-size-min;
  font-size: clamp(
    #{$text-style-heading-3-font-size-min},
    calc(1rem + #{$text-style-heading-3-font-size-modifier}),
    #{$text-style-heading-3-font-size-max}
  );
  font-weight: $text-style-heading-3-font-weight;
  letter-spacing: $text-style-heading-3-letter-spacing;
  line-height: $text-style-heading-3-line-height;
}

@mixin text-style-heading-4 {
  font-family: $text-style-heading-4-font-family;
  font-size: $text-style-heading-4-font-size-min;
  font-size: clamp(
    #{$text-style-heading-4-font-size-min},
    calc(1rem + #{$text-style-heading-4-font-size-modifier-size}),
    #{$text-style-heading-4-font-size-max}
  );
  font-weight: $text-style-heading-4-font-weight;
  letter-spacing: $text-style-heading-4-letter-spacing;
  line-height: $text-style-heading-4-line-height;
}
