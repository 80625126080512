@use './variables.scss' as *;

.centered {
  text-align: center;
}

.content-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 487px;

  @media (min-width: $desktop-breakpoint) {
    max-width: 845px;
  }
}
