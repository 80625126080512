@use '../01_global/config' as *;

.form {
  margin-bottom: $space-50;
  margin-left: auto;
  margin-right: auto;
  max-width: 411px;
  width: 100%;

  .contextual-ctas {
    @include text-style-body-2;

    text-align: center;
  }

  &__ctas {
    @media (min-width: $mobile-breakpoint) {
      padding-left: $space-90;
      padding-right: $space-90;
    }
  }
}

.button {
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.67;
  }
}
